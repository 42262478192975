/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}
.ReactVirtualized__Table__row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
          flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}

