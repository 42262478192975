* {
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html, body, #ls-darkroom {
    width: 100%;
    height: 100%;
}

a {
    color: #000;
    text-decoration: none;
}

::selection {
    background: #000;
    color: #fff;
}

::-moz-selection {
    background: #000;
    color: #fff;
}